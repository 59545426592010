import React, { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useStore } from 'react-redux'
import { DeleteIcon } from 'uiKit/icons/Icons'
import { ServiceUserType } from 'models/ServiceUsersTypes'
import CarersSelect from '../CarersSelect/CarersSelect'
import { getServiceUsers, multipleCareProfessionalsAssign } from '../../api/serviceUsersApi'
import {
  MultipleDeleteButton,
  MultipleEditBarContainer,
  MultipleEditBarLabel,
  MultipleEditSection,
} from './MultipleEditBar.styles'

interface Props {
  botId: string
  showMultipleBar: boolean
  selectedServiceUsersId: number[]
  setDeleteUserModal: Dispatch<SetStateAction<{ isOpen: boolean, selectedUser: ServiceUserType | null }>>
  searchValue?: string
  page?: number
  rowsPerPage?: number
}

const MultipleEditBar: FC<Props> = ({
  botId,
  showMultipleBar,
  selectedServiceUsersId,
  setDeleteUserModal,
  searchValue,
  rowsPerPage,
}) => {
  const store = useStore()
  const { serviceUsersTab: { currentPage } } = store.getState()

  const multipleChangeHandler = (value, method: 'POST' | 'DELETE') => {
    const professionalsIs = value.map(professional => professional.value).join(',')
    const serviceUsersId = selectedServiceUsersId.join(',')
    multipleCareProfessionalsAssign(botId, professionalsIs, serviceUsersId, method).then(() => {
      getServiceUsers(botId, currentPage, searchValue, rowsPerPage)
    })
  }

  const multipleDeleteHandler = () => {
    setDeleteUserModal(prevState => ({
      ...prevState,
      isOpen: true
    }))
  }

  const editBarLabel = useMemo(() => {
    const isSingleUser = selectedServiceUsersId.length === 1
    return `${selectedServiceUsersId.length} ${isSingleUser ? 'user' : 'users'} selected`
  }, [selectedServiceUsersId])

  return (
    <MultipleEditBarContainer showMultipleBar={showMultipleBar}>
      <MultipleEditSection>
        <MultipleEditBarLabel>
          { editBarLabel }
        </MultipleEditBarLabel>
      </MultipleEditSection>
      <MultipleEditSection />
      <MultipleEditSection />
      <MultipleEditSection>
        <CarersSelect
          botId={botId}
          isMultipleFlow={true}
          onMultipleChangeHandler={multipleChangeHandler}
          placeholder='Manage care professionals'
        />
      </MultipleEditSection>
      <MultipleEditSection />
      <MultipleEditSection />
      <MultipleEditSection>
        <MultipleDeleteButton onClick={multipleDeleteHandler}>
          <DeleteIcon />
        </MultipleDeleteButton>
      </MultipleEditSection>
    </MultipleEditBarContainer>
  )
}

export default MultipleEditBar
