import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import Select from 'react-select'
import Input from 'uiKit/inputs/Input'
import { InputsContainer, SelectContainer, SelectLabel } from './InviteUserModal.styles'
import SelectorStyles from '../../../nlp/constants/selectorStyles'
import { ErrorsType, FormType } from './InviteUserModal'

const roleOptions = [
  {
    label: 'Professional',
    value: 'CARER'
  },
  {
    label: 'Manager',
    value: 'SUPERVISOR'
  }
]

interface Props {
  form: FormType
  setForm: Dispatch<SetStateAction<FormType>>
  errors: ErrorsType
  setErrors: Dispatch<SetStateAction<ErrorsType>>
}

export const OneUserTab: FC<Props> = ({ form, setForm, errors, setErrors }) => {
  const [selectValue, setSelectValue] = useState({
    label: roleOptions[0].label,
    value: roleOptions[0].value
  })

  const onChangeHandler = (type, value) => {
    setForm(prevState => ({
      ...prevState,
      [type]: type === 'role' ? value.value : value
    }))
    type !== 'role' && setErrors(prevState => ({
      ...prevState,
      [type]: ''
    }))

    if (type === 'role') {
      setSelectValue(value)
    }
  }

  return (
    <>
      <Input
        title='Email'
        placeholder='Enter user’s email'
        value={form.email}
        onChange={(e) => onChangeHandler('email', e.target.value)}
        error={!!errors.email}
        errorMessage={errors.email}
      />
      <InputsContainer>
        <Input
          title='First Name'
          placeholder='Enter user’s first name'
          value={form.first_name}
          onChange={(e) => onChangeHandler('first_name', e.target.value)}
          error={!!errors.first_name}
          errorMessage={errors.first_name}
        />
        <Input
          title='Last Name'
          placeholder='Enter user’s last name'
          value={form.last_name}
          onChange={(e) => onChangeHandler('last_name', e.target.value)}
          error={!!errors.last_name}
          errorMessage={errors.last_name}
        />
      </InputsContainer>
      <SelectContainer>
        <SelectLabel>
          Role
        </SelectLabel>
        <Select
          isSearchable={false}
          options={roleOptions}
          value={selectValue}
          onChange={(e) => onChangeHandler('role', e)}
          styles={{
            ...SelectorStyles,
            indicatorSeparator: provided => ({
              ...provided,
              display: 'none',
            }),
            control: provided => ({
              ...provided,
              border: '1px solid var(--color-ghost-grey)',
              borderRadius: 10,
              outline: 'none',
              boxShadow: 'none',
              height: 45,
              cursor: 'pointer',
              '&:hover': {
                border: '1px solid var(--color-ghost-grey)',
                boxShadow: 'none',
              }
            })
          }}
        />
      </SelectContainer>
    </>
  )
}
