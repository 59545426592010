import { BACKEND_URL } from '../../../configs'
import { alertError, createJsonHeaders, alertSuccess, logoutOnRequestOrResponseJson, logoutOnRequestOrResponseBlob } from '../../../api'
import { saveManagers, saveTotalElements, saveTotalPages, saveUsers } from '../actions/usersAction'
import { CurrentSessionType, DatePeriodType, ManagerType, UsersManagementType, UserType } from '../model/usersModel'
import { FormType } from '../components/InviteUserModal/InviteUserModal'

export const getUsers = async (botId: string, page: number, search = '') => {
  const encodedQuery = encodeURIComponent(search)
  return fetch(`${BACKEND_URL}/users/management/bot/${botId}?page=${page}&search=${encodedQuery}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET'
  }).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then((json: UsersManagementType) => {
        saveUsers(json.user_list)
        saveTotalPages(json.total_pages)
        saveTotalElements(json.total_elements)
        return json
      })
    }
    response.text().then(text => alertSuccess(text))
  }).catch(() => {
    alertError('Sorry but something going wrong at getting contacts please ping support!')
  })
}

export const updateUser = async (updatedUser: UserType): Promise<Response> => {
  const response = await fetch(`${BACKEND_URL}/users/management`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'PUT',
    body: JSON.stringify(updatedUser)
  })

  if (response.ok) {
    const json = await logoutOnRequestOrResponseJson(response)
    alertSuccess('User updated', 2000)
    return json
  } else {
    const errorResponse = await response.json()
    if (errorResponse.error?.message) {
      alertError(errorResponse.error.message)
    } else {
      const text = await response.text()
      alertError(`Error: ${text}`)
    }
    throw new Error('Failed to update user')
  }
}


export const assignManager = (botId: string, userId: number, managerId: number) => {
  return fetch(`${BACKEND_URL}/users/management/bot/${botId}/subordination/${userId}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify({ manager_id: managerId })
  }).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then(json => {
        alertSuccess('Manager was assigned', 2000)
        return json
      })
    }
    response.text().then(text => alertSuccess(text))
  }).catch(() => {
    alertError('Sorry but something going wrong at getting contacts please ping support!')
  })
}

export const deleteUser = async (userId: number) => {
  return fetch(`${BACKEND_URL}/users/management/${userId}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'DELETE'
  }).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then(() => {
        alertSuccess('You successfully removed user from the Chatbot.', 2000)
      })
    } else {
      alertError('This manager cannot be deleted from the platform. Please reassign their subordinates to a different manager.')
    }
  }).catch(() => {
    alertError('Sorry but something going wrong at getting contacts please ping support!')
  })
}

export const resetUserPassword = (userId: number) => {
  fetch(`${BACKEND_URL}/users/management/reset/password/${userId}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST'
  }).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then(() => {
        // eslint-disable-next-line max-len
        alertSuccess('You successfully initiated password reset. The user will change their password on the next login.', 2000)
      })
    }
  }).catch(() => {
    alertError('Sorry but something going wrong at getting contacts please ping support!')
  })
}

export const resendUserInvitation = async (userId: number) => {
  return fetch(`${BACKEND_URL}/users/management/resend/invite/${userId}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST'
  }).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then(() => {
        alertSuccess('Invitation successfully resended', 2000)
      })
    }
  }).catch(() => {
    alertError('Sorry but something going wrong at getting contacts please ping support!')
  })
}

export const uploadFile = async (botId: string, file: FormData) => {
  return fetch(`${BACKEND_URL}/users/invite/bulk?botId=${botId}`, {
    credentials: 'include',
    method: 'POST',
    body: file
  }).then(response => {
    return response
  }).catch(() => {
    alertError('Sorry but something going wrong at getting contacts please ping support!')
  })
}

export const inviteNewUser = async (form: FormType & { botId: string }) => {
  try {
    const response = await fetch(`${BACKEND_URL}/users/invite`, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'POST',
      body: JSON.stringify(form),
    })
    if (response.ok) {
      await logoutOnRequestOrResponseJson(response)
      alertSuccess(
        'You successfully invited user(s) to the Chatbot. Instructions have been sent to the user’s email.',
        2000
      )
      return response
    } else {
      const errorResponse = await response.json()
      alertError(errorResponse.error.message || 'Failed to invite new user')
      throw new Error(errorResponse.error.message || 'Failed to invite new user')
    }
  } catch (error) {
    alertError(error.message || 'Sorry but something going wrong at getting contacts please ping support!')
  }
}


export const exportConversationLogs = async (botId: string, userId: number, from: string, to: string) => {
  return fetch(`${BACKEND_URL}/ai-chat/${botId}/user/${userId}?from=${from}&to=${to}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST'
  }).then(response => {
    if (!response.ok) {
      alertError('Error exporting conversation logs')
    }
    return response.blob()
  }).then(blob => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'chat_history.csv'
    document.body.appendChild(a)
    a.click()
    a.remove()
    window.URL.revokeObjectURL(url)
  }).catch(() => {
    alertError('Error exporting conversation logs')
  })
}

export const getManagers = async (botId: string) => {
  try {
    const response = await fetch(`${BACKEND_URL}/users/management/all/${botId}/values?role=SUPERVISOR`, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET'
    })

    if (response.ok) {
      const managers = await logoutOnRequestOrResponseJson(response)
      saveManagers(managers)
      return managers
    } else {
      const errorResponse = await response.json()
      alertError(errorResponse.error.message || 'Sorry but something going wrong at getting contacts please ping support!')
      throw new Error(errorResponse.error.message || 'Failed to fetch managers')
    }
  } catch (error) {
    alertError(error.message || 'Sorry but something going wrong at getting contacts please ping support!')
    throw error
  }
}

export const getCurrentSession = async (botId: string, userId: number, signal: AbortSignal) => {
  try {
    const response = await fetch(`${BACKEND_URL}/bot/${botId}/supervision/session/user/${userId}/current`, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET',
      signal
    })

    if (response.ok) {
      return await logoutOnRequestOrResponseJson(response)
    } else {
      const errorResponse = await response.json()
      alertError(errorResponse.error.message || 'Sorry but something going wrong at getting contacts please ping support!')
      throw new Error(errorResponse.error.message || 'Failed to fetch current session')
    }
  } catch (error) {
    alertError(error.message || 'Sorry but something going wrong at getting contacts please ping support!')
    throw error
  }
}

export const getSessionsHistory = async (botId: string, userId: number, signal: AbortSignal) => {
  try {
    const response = await fetch(`${BACKEND_URL}/bot/${botId}/supervision/session/user/${userId}/history`, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET',
      signal
    })

    if (response.ok) {
      return await logoutOnRequestOrResponseJson(response)
    } else {
      const errorResponse = await response.json()
      alertError(errorResponse.error.message || 'Sorry but something going wrong at getting contacts please ping support!')
      throw new Error(errorResponse.error.message || 'Failed to fetch sessions history')
    }
  } catch (error) {
    alertError(error.message || 'Sorry but something going wrong at getting contacts please ping support!')
    throw error
  }
}

export const editSupervision = async (botId: string, supervisionId: string, updatedDates: any) => {
  try {
    const response = await fetch(`${BACKEND_URL}/bot/${botId}/supervision/session/${supervisionId}`, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'PUT',
      body: JSON.stringify(updatedDates)
    })

    if (response.ok) {
      const json = await logoutOnRequestOrResponseJson(response)
      alertSuccess('Supervision updated successfully', 2000)
      return json
    } else {
      const errorResponse = await response.json()
      console.log(errorResponse)
      alertError(errorResponse.error.message || 'Sorry but something going wrong at getting contacts please ping support!')
      throw new Error(errorResponse.error.message || 'Failed to edit supervision')
    }
  } catch (error) {
    alertError(error.message || 'Sorry but something going wrong at getting contacts please ping support!')
    throw error
  }
}

export const openSupervisionTranscript = async (botId: string, supervisionId: string, fileName: string) => {
  window.open(`${BACKEND_URL}/bot/${botId}/supervision/session/${supervisionId}/transcript/${fileName}`, '_blank')
}

export const generateSupervisionTranscript = async (botId: string, supervisionId: string) => {
  try {
    const response = await fetch(`${BACKEND_URL}/bot/${botId}/supervision/session/${supervisionId}/transcript`, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET'
    })

    if (response.ok) {
      return await logoutOnRequestOrResponseBlob(response)
    } else {
      const errorResponse = await response.json()
      alertError(errorResponse.error.message || 'Sorry but something going wrong at getting contacts please ping support!')
      throw new Error(errorResponse.error.message || 'Failed to generate transcript')
    }
  } catch (error) {
    alertError(error.message || 'Sorry but something going wrong at getting contacts please ping support!')
    throw error
  }
}

export const openSupervisionSummary = async (botId: string, supervisionId: string, fileName: string) => {
  window.open(`${BACKEND_URL}/bot/${botId}/supervision/session/${supervisionId}/output-form/${fileName}`, '_blank')
}

export const generateSupervisionSummary = async (botId: string, supervisionId: string) => {
  try {
    const response = await fetch(`${BACKEND_URL}/bot/${botId}/supervision/session/${supervisionId}/output-form`, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET'
    })

    if (response.ok) {
      return await logoutOnRequestOrResponseBlob(response)
    } else {
      const errorResponse = await response.json()
      alertError(errorResponse.error.message || 'Sorry but something going wrong at getting contacts please ping support!')
      throw new Error(errorResponse.error.message || 'Failed to generate summary')
    }
  } catch (error) {
    alertError(error.message || 'Sorry but something going wrong at getting contacts please ping support!')
    throw error
  }
}

export const completeSupervision = async (botId: string, userId: number, summary: string) => {
  try {
    const response = await fetch(`${BACKEND_URL}/bot/${botId}/supervision/session/user/${userId}/complete`, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'POST',
      body: JSON.stringify(summary)
    })

    if (response.ok) {
      return await logoutOnRequestOrResponseJson(response)
    } else {
      const errorResponse = await response.json()
      alertError(errorResponse.error.message || 'Sorry but something going wrong at getting contacts please ping support!')
      throw new Error(errorResponse.error.message || 'Failed to complete supervision')
    }
  } catch (error) {
    alertError(error.message || 'Sorry but something going wrong at getting contacts please ping support!')
    throw error
  }
}

