import styled, { css } from 'styled-components'
import { WILD_SAND } from 'constants/colors'

export const ActionSelectorContainer = styled.div`
  width: 100%;
`

export const ActionSelectorButton = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  display: block;
  
  & svg {
    display: block;
  }
`

export const PopoverButton = styled.button`
  padding: 16px;
  border: none;
  background-color: transparent;
  color: ${props => (props.hasAttentionStyles ? 'var(--color-text-error)' : 'var(--color-text-primary)')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  position: relative;
  
  &:hover {
    background-color: var(--color-hover-overlay-primary);
  }
  
  ${props => props.hasSubMenu && css`
    background-color: var(--color-hover-overlay-primary);
  `}
  
  &:disabled {
    background-color: ${WILD_SAND};
    color: var(--color-text-placeholder);
  }
`

export const IconContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  
  & svg {
    display: block
  }
`

export const SubMenu = styled.div`
  position: absolute;
  background: white;
  top: 0;
  right: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(53, 64, 82, 0.32);
  min-width: 150px;
  margin-right: 8px;
`
