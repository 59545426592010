import { BACKEND_URL } from '../../../configs'
import { alertError, createJsonHeaders, alertSuccess, logoutOnRequestOrResponseJson } from '../../../api'
import {
  saveCareProfessionals,
  saveServiceUsers,
  saveTotalElements
} from '../actions/serviceUsersActions'
import { CareProfessionalType, ServiceUserType } from 'models/ServiceUsersTypes'
import { DatePeriodType } from '../../professionals/model/usersModel'

type GetAllPatientType = {
  data: {
    content: ServiceUserType[]
    totalPages: number
    totalElements: number
    last: boolean
    size: number
    number: number
    pageable: any
    sort: any
    numberOfElements: number
    first: boolean
    empty: boolean
  }
}

export type ServiceUserDataType = {
  firstName: string
  lastName: string
  botId: number
  assignedProfessionals: number[]
}

export const getServiceUsers = async (botId: string, page: number, search = '', size = 10) => {
  const encodedQuery = encodeURIComponent(search)

  const rowsPerPage = typeof size === 'string' ? 99999 : size
  return fetch(`${BACKEND_URL}/patients/${botId}/all?page=${page}&search=${encodedQuery}&size=${rowsPerPage}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET'
  }).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then(({ data }: GetAllPatientType) => {
        saveServiceUsers(data.content)
        saveTotalElements(data.totalElements)
      })
    }
    response.text().then(text => alertSuccess(text))
  }).catch(() => {
    alertError('Sorry but something going wrong please ping support!')
  })
}

export const getAllCareProfessionals = async (botId: string) => {
  return fetch(`${BACKEND_URL}/users/management/all/${botId}/values`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET'
  }).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then((json: CareProfessionalType[]) => {
        saveCareProfessionals(json)
      })
    }
    response.text().then(text => alertSuccess(text))
  }).catch(() => {
    alertError('Sorry but something going wrong please ping support!')
  })
}

export const deleteUsers = async (botId: string, patentIds: string) => {
  return fetch(`${BACKEND_URL}/patients/${botId}/delete?patientIdsParam=${patentIds}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'DELETE'
  }).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then(() => {
        alertSuccess('You successfully removed user from the Chatbot.', 2000)
      })
    }
  }).catch(() => {
    alertError('Sorry but something going wrong please ping support!')
  })
}

export const createServiceUser = async (botId: string, userData: ServiceUserDataType, isNewUser = false): Promise<Response> => {
  return fetch(`${BACKEND_URL}/patients/${botId}/create`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(userData)
  }).then((response) => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then((json) => {
        alertSuccess(isNewUser ? 'User created' : 'User updated', 2000)
        return json
      })
    }
    response.text().then(text => alertSuccess(text))
  }).catch(() => {
    alertError('Sorry but something going wrong please ping support!')
  })
}

export const serviceUsersMultiCreating = async (botId: string, file: FormData) => {
  return fetch(`${BACKEND_URL}/patients/${botId}/bulk`, {
    credentials: 'include',
    method: 'POST',
    body: file
  }).then(response => {
    return response
  }).catch(() => {
    alertError('Sorry but something going wrong please ping support!')
  })
}

export const uploadPatientKnowledge = async (botId: string, patientId: number, file: FormData) => {
  return fetch(`${BACKEND_URL}/private/bot/${botId}/knowledge/upload?level=CARE_PLAN&patientId=${patientId}&train=true`, {
    credentials: 'include',
    method: 'POST',
    body: file
  }).then(response => {
    if (response.ok) {
      alertSuccess('Successfully uploaded', 2000)
    }
    return response
  }).catch(() => {
    alertError('Sorry but something going wrong please ping support!')
  })
}

export const deletePatientCarePlan = async (botId: string, sourceId: string) => {
  return fetch(`${BACKEND_URL}/private/bot/${botId}/knowledge/?source_id=${sourceId}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'DELETE'
  }).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then(() => {
        alertSuccess('You successfully removed the care plan.', 2000)
      })
    }
  })
}

export const showDuplicates = async (botId: string) => {
  return fetch(`${BACKEND_URL}/patients/${botId}/duplicates`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET'
  }).then(response => {
    return response.json()
  }).catch((err) => {
    console.error('Sorry but something going wrong please ping support!', err)
  })
}

export const multipleCareProfessionalsAssign = async (botId: string, professionalId: string, patientId: string, method: 'POST' | 'DELETE') => {
  return fetch(`${BACKEND_URL}/patients/${botId}/assign?userIdsParam=${professionalId}&patientIdsParam=${patientId}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: method
  }).then(response => {
    if (response.ok) {
      if (method == 'POST') {
        alertSuccess('Professionals successfully added to selected users.', 2000)
      } else {
        alertSuccess('Professionals successfully removed for selected users.', 2000)
      }
    }
    return response.json()
  }).catch((err) => {
    console.error('Sorry but something going wrong please ping support!', err)
  })
}

export const exportConversations = async (botId: string, userId: number, from: string, to: string) => {
  return fetch(`${BACKEND_URL}/patients/${botId}/user/${userId}?from=${from}&to=${to}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST',
  }).then(response => {
    if (!response.ok) {
      alertError('Error exporting conversation logs')
    }
    return response.blob()
  }).then(blob => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'chat_history.csv'
    document.body.appendChild(a)
    a.click()
    a.remove()
    window.URL.revokeObjectURL(url)
  }).catch(() => {
    alertError('Error exporting conversation logs')
  })
}
