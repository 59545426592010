import styled from 'styled-components'

export const StatusContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;

`

export const StatusTitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${props => props.color || 'var(--color-text-primary)'};
`
