import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import ReactTooltip from 'react-tooltip'
import { SupervisionStatus } from '../SupervisionStatus/SupervisionStatus'
import {
  CompletionStatusWrapper,
  ItemLabel,
  DatePickerWrapper,
  FilesSection,
  TimeSlotSection,
  FileWrapper,
  FileAction,
  Title,
  ActionContainer,
  ActionButton,
  SummarySection,
  SummaryTextarea,
  AttentionWarning, DatepickerActionBar, DatepickerActionButton,
} from './SupervisionManagementModal.styles'
import { DownloadIcon } from 'uiKit/icons/DownloadIcon'
import { ExternalLinkIcon } from 'uiKit/icons/ExternalLinkIcon'
import './react-datepicker.css'
import { SupervisionStatuses } from '../../helpers/constants'
import { EditIcon } from 'uiKit/icons/EditIcon'
import { editSupervision, getCurrentSession } from '../../api/usersApi'
import { CurrentSessionType, UserType } from '../../model/usersModel'
import LoaderScreen from 'uiKit/loaders/loaderScreen'
import { downloadSupervisionResultHandler, downloadSupervisionTranscript } from '../../helpers/supervisionFileHelper'

interface Props {
  botId: string
  selectedUser: UserType
  currentSession: CurrentSessionType
  setCurrentSession: Dispatch<SetStateAction<CurrentSessionType>>
  hasExtraUpdate: boolean
  setHasExtraUpdate: Dispatch<SetStateAction<boolean>>
}

export const CurrentSupervisionTab: FC<Props> = ({
  botId,
  selectedUser,
  currentSession,
  setCurrentSession,
  hasExtraUpdate,
  setHasExtraUpdate
}) => {
  const [isOpenDate, setIsOpenDate] = useState({
    deadlineAt: false,
    scheduledAt: false
  })
  const [selectedDate, setSelectedDate] = useState({
    deadlineAt: currentSession.deadlineAt,
    scheduledAt: currentSession.scheduledAt
  })
  const [isLoadingCurrentSession, setIsLoadingCurrentSession] = useState(true)
  const isSummaryDisabled = useMemo(() => {
    return [
      SupervisionStatuses.UPCOMING,
      SupervisionStatuses.TO_BE_COMPLETED,
      SupervisionStatuses.OVERDUE
    ].includes(currentSession.status)
  }, [currentSession.status])

  const isTextAreaDisabled = useMemo(() => currentSession.status !== SupervisionStatuses.SUBMITTED, [currentSession.status])
  const isDatepickerDisabled = useMemo(() => currentSession.status === SupervisionStatuses.SUBMITTED, [currentSession.status])

  const summaryTitle = useMemo(() => currentSession.outputFormFileName || 'Unavailable', [currentSession])
  const transcriptTitle = useMemo(() => currentSession.transcriptFileName || 'Unavailable', [currentSession])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    getCurrentSession(botId, selectedUser.id, signal).then(result => {
      setCurrentSession(result)
      setSelectedDate({
        deadlineAt: result.deadlineAt,
        scheduledAt: result.scheduledAt
      })
      setIsLoadingCurrentSession(false)
    })

    return () => {
      controller.abort()
    }
  }, [hasExtraUpdate])

  const textareaChangeHandler = (e) => {
    const value = e.target.value
    setCurrentSession(prevState => ({
      ...prevState,
      summary: {
        ...prevState.summary,
        managerSummary: value
      },
    }))
  }

  const applyHandler = (type: string) => {
    setIsOpenDate(prevState => ({
      ...prevState,
      [type]: false
    }))
    const dates = {
      scheduledAt: selectedDate.scheduledAt,
      deadlineAt: selectedDate.deadlineAt
    }

    editSupervision(botId, currentSession.id, dates).then(() => {
      setHasExtraUpdate(prevState => !prevState)
    })
  }

  const onFocusHandler = (type: string) => {
    setIsOpenDate(prevState => ({
      ...prevState,
      [type]: true
    }))
  }

  const cancelHandler = (type: string) => {
    setSelectedDate(prevState => ({
      ...prevState,
      [type]: currentSession[type]
    }))

    setIsOpenDate(prevState => ({
      ...prevState,
      [type]: false
    }))
  }

  const onChangeHandler = (type, date) => {
    setSelectedDate(prevState => ({
      ...prevState,
      [type]: new Date(date).toISOString()
    }))
  }

  const datepickerIcon = (type: string, isDisabled: boolean) => {
    return (
      <ActionButton
        disabled={isDisabled}
        onClick={() => onFocusHandler(type)}
      >
        <EditIcon />
      </ActionButton>
    )
  }

  return (
    <>
      {isLoadingCurrentSession ? (
        <LoaderScreen
          size={{
            circleSize: 60,
            border: 8
          }}
        />
      ) : (
        <>
          <TimeSlotSection>
            <DatePickerWrapper>
              <ItemLabel>Deadline</ItemLabel>
              <DatePicker
                open={isOpenDate.deadlineAt}
                showIcon
                dateFormat={'dd/MM/YYYY'}
                selected={new Date(currentSession.deadlineAt)}
                onChange={(date) => onChangeHandler('deadlineAt', date)}
                icon={datepickerIcon('deadlineAt', isDatepickerDisabled)}
                shouldCloseOnSelect={false}
                calendarStartDay={1}
                onFocus={() => onFocusHandler('deadlineAt')}
                onClickOutside={() => cancelHandler('deadlineAt')}
                minDate={new Date()}
                disabled={isDatepickerDisabled}
              >
                <DatepickerActionBar>
                  <DatepickerActionButton
                    onClick={() => cancelHandler('deadlineAt')}
                  >
                    Cancel
                  </DatepickerActionButton>
                  <DatepickerActionButton
                    onClick={() => applyHandler('deadlineAt')}
                    isPrimary={true}
                  >
                    Apply
                  </DatepickerActionButton>
                </DatepickerActionBar>
              </DatePicker>
            </DatePickerWrapper>
            <DatePickerWrapper>
              <ItemLabel>
                Available from
                <ReactTooltip
                  className='modal-tooltip'
                  place='top'
                  type='dark'
                  effect='solid'
                  id='AvailableАromTooltip-1'
                  show={true}
                >
                  Care Professional will be able to access their supervision from this date.
                </ReactTooltip>
                <img
                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                  data-tip
                  data-for='AvailableАromTooltip-1'
                  src='/images/platform/information-button.svg'
                  alt='info icon'
                />
              </ItemLabel>
              <DatePicker
                open={isOpenDate.scheduledAt}
                showIcon
                dateFormat={'dd/MM/YYYY'}
                selected={new Date(currentSession.scheduledAt)}
                onChange={(date) => onChangeHandler('scheduledAt', date)}
                icon={datepickerIcon('scheduledAt', isDatepickerDisabled)}
                shouldCloseOnSelect={false}
                calendarStartDay={1}
                onFocus={() => onFocusHandler('scheduledAt')}
                onClickOutside={() => cancelHandler('scheduledAt')}
                minDate={new Date()}
                maxDate={new Date(currentSession.deadlineAt)}
                disabled={isDatepickerDisabled}
              >
                <DatepickerActionBar>
                  <DatepickerActionButton
                    onClick={() => cancelHandler('scheduledAt')}
                  >
                    Cancel
                  </DatepickerActionButton>
                  <DatepickerActionButton
                    onClick={() => applyHandler('scheduledAt')}
                    isPrimary={true}
                  >
                    Apply
                  </DatepickerActionButton>
                </DatepickerActionBar>
              </DatePicker>
            </DatePickerWrapper>
            <CompletionStatusWrapper>
              <ItemLabel>Completion status</ItemLabel>
              <SupervisionStatus type={currentSession.status} />
            </CompletionStatusWrapper>
          </TimeSlotSection>
          <FilesSection>
            <FileWrapper>
              <ItemLabel>Supervision summary</ItemLabel>
              <FileAction isDisabled={isSummaryDisabled}>
                <Title isDisabled={isSummaryDisabled}>{ summaryTitle }</Title>
                <ActionContainer>
                  <ActionButton
                    disabled={isSummaryDisabled}
                    onClick={() => downloadSupervisionResultHandler(botId, currentSession.id, currentSession.outputFormFileName, false)}
                  >
                    <DownloadIcon />
                  </ActionButton>
                  <ActionButton
                    disabled={isSummaryDisabled}
                    onClick={() => downloadSupervisionResultHandler(botId, currentSession.id, currentSession.outputFormFileName, true)}
                  >
                    <ExternalLinkIcon />
                  </ActionButton>
                </ActionContainer>
              </FileAction>
            </FileWrapper>

            <FileWrapper>
              <ItemLabel>Supervision transcript</ItemLabel>
              <FileAction isDisabled={isSummaryDisabled}>
                <Title isDisabled={isSummaryDisabled}>{ transcriptTitle }</Title>
                <ActionContainer>
                  <ActionButton
                    disabled={isSummaryDisabled}
                    onClick={() => downloadSupervisionTranscript(botId, currentSession.id, currentSession.transcriptFileName, false)}
                  >
                    <DownloadIcon />
                  </ActionButton>
                  <ActionButton
                    disabled={isSummaryDisabled}
                    onClick={() => downloadSupervisionTranscript(botId, currentSession.id, currentSession.transcriptFileName, true)}
                  >
                    <ExternalLinkIcon />
                  </ActionButton>
                </ActionContainer>
              </FileAction>
            </FileWrapper>
          </FilesSection>
          <SummarySection>
            <ItemLabel>Manager’s summary</ItemLabel>
            <SummaryTextarea
              placeholder='Type your comment here...'
              disabled={isTextAreaDisabled}
              value={currentSession.summary?.managerSummary || ''}
              onChange={textareaChangeHandler}
            />
            {!!currentSession.summary?.managerSummary && (
              <AttentionWarning>
                * You won’t be able to edit your comment once you complete supervision. Please review it before submission.
              </AttentionWarning>
            )}
          </SummarySection>
        </>
      )}
    </>
  )
}
