import React, { FC, useMemo, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import classNames from 'classnames'
import ModalTemplate from 'uiKit/ModalTemplate'
import { CurrentSupervisionTab } from './CurrentSupervisionTab'
import { CompletedSupervisionTab } from './CompletedSupervisionTab'
import { CurrentSessionType, UserType } from '../../model/usersModel'
import classes from '../../../widget/components/AddGreetingModal/styles.module.scss'
import { SupervisionStatuses } from '../../helpers/constants'
import { completeSupervision } from '../../api/usersApi'


type TabType = 'Current' | 'Completed'
const tabs: TabType[] = ['Current', 'Completed']

interface Props {
  botId: string
  isOpenSupervisionManagementModal: boolean
  onClose: () => void
  selectedUser: UserType
}

export const SupervisionManagementModal: FC<Props> = ({
  botId,
  isOpenSupervisionManagementModal,
  onClose,
  selectedUser
}) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [hasExtraUpdate, setHasExtraUpdate] = useState(false)
  const [currentSession, setCurrentSession] = useState<CurrentSessionType>({
    completedAt: '',
    id: '',
    professional: undefined,
    scheduledAt: '',
    summary: {
      aiSummary: undefined,
      managerSummary: '',
      finalReportGenerated: false
    },
    updatedAt: '',
    status: SupervisionStatuses.NOT_ASSIGNED,
    createdAt: '',
    deadlineAt: '',
    outputFormFileName: '',
    transcriptFileName: '',
  })

  const modalTitle = useMemo(() => {
    return `${selectedUser.first_name} ${selectedUser.last_name}'s supervisions`
  }, [selectedUser])

  const completeSupervisionHandler = () => {
    const summary = currentSession.summary.managerSummary
    setCurrentSession(prevState => ({
      ...prevState,
      summary: {
        ...prevState.summary,
        managerSummary: ''
      },
    }))
    completeSupervision(botId, selectedUser.id, summary).then(() => {
      setHasExtraUpdate(prevState => !prevState)
    })
  }

  const modalIcon = () => {
    return (
      <>
        <ReactTooltip
          className='modal-tooltip'
          place="top"
          type="dark"
          effect="solid"
          id="DatePickerTooltip-1"
          show={true}
        >
          An automatically scheduled check-in to review the care professional's performance, occurring every 3 months for each team member.
        </ReactTooltip>
        <img
          style={{ cursor: 'pointer', marginLeft: '10px' }}
          data-tip
          data-for="DatePickerTooltip-1"
          src="/images/platform/information-button.svg"
          alt="info icon"
        />
      </>
    )
  }

  return (
    <ModalTemplate
      title={modalTitle}
      titleIcon={modalIcon()}
      open={isOpenSupervisionManagementModal}
      onSave={completeSupervisionHandler}
      onClose={onClose}
      hasPrimaryButton={selectedTab === tabs[0]}
      primaryButtonTitle="Complete supervision"
      isPrimaryButtonDisabled={!currentSession.summary?.managerSummary}
    >
      <>
        <div className={classes.tabs}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={selectedTab === tab ? classes.activeTab : classes.tab}
              onClick={() => setSelectedTab(tab)}>
              {tab}
            </div>
          ))}
        </div>
        <div
          className={classNames(classes.body, classes.supervisionBody)}
          id='scrollable'
          style={{ height: 386 }}
        >
          {selectedTab === tabs[0] && (
            <CurrentSupervisionTab
              botId={botId}
              selectedUser={selectedUser}
              currentSession={currentSession}
              setCurrentSession={setCurrentSession}
              hasExtraUpdate={hasExtraUpdate}
              setHasExtraUpdate={setHasExtraUpdate}
            />
          )}
          {selectedTab === tabs[1] && (
            <CompletedSupervisionTab
              botId={botId}
              selectedUser={selectedUser}
            />
          )}
        </div>
      </>
    </ModalTemplate>
  )
}
