import { store } from '../../../index'

export const SET_IS_LOADING_USERS = 'SET_IS_LOADING_USERS'
export const SAVE_USERS = 'SAVE_USERS'
export const SAVE_MANAGERS = 'SAVE_MANAGERS'
export const SAVE_TOTAL_PAGES = 'SAVE_TOTAL_PAGES'
export const SAVE_TOTAL_ELEMENTS = 'SAVE_TOTAL_ELEMENTS'
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE'

export const setIsLoadingUsers = (isLoadingUsers) => {
  store.dispatch({
    type: SET_IS_LOADING_USERS,
    isLoadingUsers
  })
}

export const saveUsers = (users) => {
  store.dispatch({
    type: SAVE_USERS,
    users
  })
}

export const saveManagers = (managers) => {
  store.dispatch({
    type: SAVE_MANAGERS,
    managers
  })
}

export const saveTotalElements = (totalElements) => {
  store.dispatch({
    type: SAVE_TOTAL_ELEMENTS,
    totalElements
  })
}

export const saveTotalPages = (totalPages) => {
  store.dispatch({
    type: SAVE_TOTAL_PAGES,
    totalPages
  })
}

export const updateCurrentPage = (currentPage) => {
  store.dispatch({
    type: UPDATE_CURRENT_PAGE,
    currentPage
  })
}
