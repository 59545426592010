import {
  generateSupervisionSummary,
  generateSupervisionTranscript,
  openSupervisionSummary,
  openSupervisionTranscript,
} from '../api/usersApi'

const saveByteArray = (reportName: string, blob: Blob): void => {
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = reportName
  link.target = '_self'
  link.click()
}

const openByteArray = (blob: Blob): void => {
  const link = window.URL.createObjectURL(blob)
  window.open(link, '_blank')
}

export const downloadSupervisionResultHandler = (
  botId: string,
  supervisionId: string,
  outputFormFileName: string,
  isOpen: boolean
) => {
  isOpen
    ? openSupervisionSummary(botId, supervisionId, outputFormFileName)
    : generateSupervisionSummary(botId, supervisionId).then(blob => saveByteArray(outputFormFileName, blob))
}

export const downloadSupervisionTranscript = (
  botId: string,
  supervisionId: string,
  transcriptFileName: string,
  isOpen: boolean
) => {
  isOpen
    ? openSupervisionTranscript(botId, supervisionId, transcriptFileName)
    : generateSupervisionTranscript(botId, supervisionId).then(blob => saveByteArray(transcriptFileName, blob))
}
