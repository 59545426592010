import styled from 'styled-components'
import {
  InputsContainer as BaseInputsContainer
} from '../../../professionals/components/InviteUserModal/InviteUserModal.styles'

export const InputsContainer = styled(BaseInputsContainer)`
  margin-bottom: 24px;
`

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  & > div {
    width: 52%;
  }
`

export const DropdownLabel = styled.span`
  color: var(--color-text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 8px;
`
