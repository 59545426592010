import React, { useState } from 'react'
import { BACKEND_URL, HOME_PATH } from '../../../configs'
import { createJsonHeaders } from '../../../api'
import Input from './Input'
import Button from './Button'
import { isStringEmpty } from '../../../helpers/isStringEmpty'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  main: {
    flex: '0 0 50%',
    maxWidth: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '40px',
    position: 'relative',
  },
  demoForm: {
    width: '60%',
    margin: '0 0 32px',
    textAlign: 'center',
  },
  demoDiv: {
    margin: '32px 0 8px',
    textAlign: 'left',
  },
  formTitle: {
    fontSize: '18px',
    fontWeight: '500',
    margin: '0 0 24px',
    color: theme.palette.text.primary,
  },
  errorMessage: {
    color: 'red',
  },
  backLink: {
    marginTop: '16px',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    textDecoration: 'underline',
  }
})

const defaultErrorMessage = 'Invalid Validation code'

const OtpPage = ({ location, classes, authenticateWithReferrer }) => {
  const [otp, setOtp] = useState('')
  const [error, setError] = useState('')
  const username = location.state?.username

  const handleSubmitOtp = () => {
    if (!otp) {
      setError('Please enter the Validation code')
      return
    }

    fetch(`${BACKEND_URL}/auth/verify-otp`, {
      method: 'POST',
      headers: createJsonHeaders(),
      credentials: 'include',
      body: JSON.stringify({ username, otp }),
    })
      .then(response => {
        if (response.ok) {
          const from = { pathname: HOME_PATH + '/' }
          authenticateWithReferrer(from)
        } else {
          setError('Invalid or expired Validation code.')
        }
      })
      .catch(() => setError('An error occurred. Please try again'))
  }

  const handleChangeOtp = e => {
    const value = e.target.value
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value)
      setError(value.length === 6 ? '' : 'Validation code must be exactly 6 digits')
    } else {
      setError('Validation code can only contain digits and must be 6 characters long')
    }
  }
  const handleBackToLogin = () => {
    window.open('/login', '_self')
  }

  return (
    <main className={classes.main}>
      <div className={`${classes.demoForm} animated zoomIn faster`}>
        <h4 className={classes.formTitle}>
          Verify Validation Code
        </h4>

        <div className={classes.demoDiv}>
          <Input
            id="otp"
            name="otp"
            type="text"
            placeholder="Enter your Validation code"
            errorLabel=""
            required={true}
            value={otp}
            onChange={handleChangeOtp}
            pressEnterCallback={handleSubmitOtp}
          />

          {error && (
            <div className={classes.errorMessage}>
              {!isStringEmpty(error) ? error : defaultErrorMessage}
            </div>
          )}
        </div>

        <Button
          type="submit"
          title="Submit Code"
          onClick={handleSubmitOtp}
          disabled={otp.length !== 6 || !!error}
        />

        <div className={classes.backLink} onClick={handleBackToLogin}>
          Back to Login
        </div>
      </div>
    </main>
  )
}

export default withStyles(styles)(OtpPage)
