import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import ModalTemplate from 'uiKit/ModalTemplate'
import { FormFields, TabType } from 'models/ServiceUsersTypes.d'
import classes from '../../../widget/components/AddGreetingModal/styles.module.scss'
import { OneUserTab } from './OneUserTab'
import { MultipleUsersTab } from '../../../professionals/components/InviteUserModal/MultipleUsersTab'
import { UploadingStatuses } from '../../../professionals/helpers/constants'
import {
  createServiceUser,
  getServiceUsers,
  serviceUsersMultiCreating,
  showDuplicates,
} from '../../api/serviceUsersApi'
// @ts-ignore
import Template from '../../helpers/Template.csv'

const tabs: TabType[] = ['One service user', 'Multiple service users']

interface Props {
  botId: string
  isAddNewServiceUserModalOpen: boolean
  onClose: () => void
  setDuplicateAttentionModal: Dispatch<SetStateAction<{ isOpen: boolean, duplicateUsers: string }>>
}

export const AddNewServiceUserModal: FC<Props> = ({
  botId,
  isAddNewServiceUserModalOpen,
  onClose,
  setDuplicateAttentionModal
}) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [isUploading, setIsUploading] = useState(false)
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)
  const [uploadingStatus, setUploadingStatus] = useState(UploadingStatuses.PENDING)
  const [fileName, setFileName] = useState('')
  const [form, setForm] = useState({
    [FormFields.FIRST_NAME]: '',
    [FormFields.LAST_NAME]: '',
    [FormFields.CARE_PROFESSIONALS]: [],
  })
  const [errors, setErrors] = useState({
    first_name: '',
    last_name: ''
  })

  const handleSave = () => {
    if (!form[FormFields.FIRST_NAME]) {
      setErrors(prevState => ({
        ...prevState,
        [FormFields.FIRST_NAME]: 'First Name can’t be empty'
      }))
    }

    if (!form[FormFields.LAST_NAME]) {
      setErrors(prevState => ({
        ...prevState,
        [FormFields.LAST_NAME]: 'Last Name can’t be empty'
      }))
    }

    if (!form[FormFields.FIRST_NAME] || !form[FormFields.LAST_NAME]) {
      setIsPrimaryButtonDisabled(true)
      return
    }
    const userData = {
      firstName: form[FormFields.FIRST_NAME],
      lastName: form[FormFields.LAST_NAME],
      botId: Number(botId),
      assignedProfessionals: form[FormFields.CARE_PROFESSIONALS]
    }

    createServiceUser(botId, userData, true).then(() => {
      getServiceUsers(botId, 0).then(() => {
        showDuplicates(botId).then(res => {
          if (res.length) {
            setDuplicateAttentionModal({
              isOpen: true,
              duplicateUsers: res.join(', ')
            })
          }
        })
      })
    })

    onClose()
  }

  const uploadResponseHandler = (response) => {
    if (response.status === 200) {
      setUploadingStatus(UploadingStatuses.SUCCESS)
      getServiceUsers(botId, 0).then(() => {
        showDuplicates(botId)
      })
    } else if (response.status === 500) {
      setUploadingStatus(UploadingStatuses.ERROR)
    }
  }

  const handleUpload = (event) => {
    setIsUploading(true)
    const file = event.target.files[0]
    const formData = new FormData()
    if (file) {
      setFileName(file.name)
      formData.append('file', file)
    }

    serviceUsersMultiCreating(botId, formData).then(response => {
      setIsUploading(false)
      uploadResponseHandler(response)
    })
  }

  return (
    <ModalTemplate
      title='Add service users to CareBrain'
      primaryButtonTitle='Add'
      isPrimaryButtonDisabled={isPrimaryButtonDisabled}
      open={isAddNewServiceUserModalOpen}
      onClose={onClose}
      onSave={handleSave}
      hasPrimaryButton={selectedTab === tabs[0]}
    >
      <>
        <div className={classes.tabs}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={selectedTab === tab ? classes.activeTab : classes.tab}
              onClick={() => setSelectedTab(tab)}>
              {tab}
            </div>
          ))}
        </div>
        <div
          className={classes.body}
          id='scrollable'
          style={{ height: 386 }}
        >
          {selectedTab === tabs[0] && (
            <OneUserTab
              botId={botId}
              form={form}
              errors={errors}
              setForm={setForm}
              setErrors={setErrors}
              setIsPrimaryButtonDisabled={setIsPrimaryButtonDisabled}
            />
          )}
          {selectedTab === tabs[1] && (
            <MultipleUsersTab
              onUpload={handleUpload}
              isUploading={isUploading}
              uploadingStatus={uploadingStatus}
              fileName={fileName}
              template={Template}
              title='File should be in format of two columns: “First Name” and “Second Name” of the service user.'
              errorText='Invalid file format or data. Please ensure your document has both first and last names filled for every user.'
            />
          )}
        </div>
      </>
    </ModalTemplate>
  )
}
