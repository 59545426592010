import React, { FC, useState } from 'react'
import { useStore } from 'react-redux'
import { getUsers, updateUser } from '../../api/usersApi'
import { UserType } from '../../model/usersModel'
import {
  InputEditorButton,
  InputEditorContainer,
  InputEditorHolder,
  InputEditorInput
} from './InputEditor.styles'

interface Props {
  user: UserType
  value: string
  userKey: string
  botId: string
}

export const InputEditor: FC<Props> = ({
  user,
  value,
  userKey,
  botId
}) => {
  const store = useStore()
  const { professionalsTab: { currentPage } } = store.getState()
  const [isInputOpen, setIsInputOpen] = useState(false)
  const [inputValue, setInputValue] = useState(value)

  const onChangeHandler = (e) => {
    const value = e.target.value
    setInputValue(value)
  }

  const onBlurHandler = () => {
    if (value !== inputValue) {
      const userData = {
        ...user,
        [userKey]: inputValue
      }
      updateUser(userData).then(() => {
        getUsers(botId, currentPage)
      })
    }
    setIsInputOpen(false)
  }

  return (
    <InputEditorContainer>
      {isInputOpen ? (
        <InputEditorHolder>
          <InputEditorInput
            type='text'
            data-autotest={'atom-name-input'}
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            value={inputValue}
            autoFocus
          />
        </InputEditorHolder>
      ) : (
        <InputEditorButton
          onClick={() => setIsInputOpen(true)}
        >
          {value}
        </InputEditorButton>
      )}
    </InputEditorContainer>
  )
}
