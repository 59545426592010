import React, { FC, useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import { debounce } from 'lodash'
import Headline from 'uiKit/texts/Headline'
import LoaderScreen from 'uiKit/loaders/loaderScreen'
import Search from 'uiKit/Search/Search'
import Pagination from 'uiKit/table/Pagination'
import { ProfessionalsTable } from './components/ProfessionalsTable/ProfessionalsTable'
import { DEFAULT_DEBOUNCE_TIME } from '../../constants/time'

import {
  ButtonTitle,
  UsersButtonWrap,
  UsersContainer,
  UsersControlLine,
  UserSearchWrap,
  UsersHeadlineWrap,
} from './Professionals.styles'
import { deleteUser, getManagers, getUsers } from './api/usersApi'
import { ManagerType, UserType } from './model/usersModel'
import NoChartData from '../../uiKit/NoChartData'
import { InviteUserModal } from './components/InviteUserModal/InviteUserModal'
import { DeleteUserModal } from './components/DeleteUserModal/DeleteUserModal'
import { setIsLoadingUsers, updateCurrentPage } from './actions/usersAction'
import { SupervisionManagementModal } from './components/SupervisionManagementModal/SupervisionManagementModal'
export const inputSearchStyles = {
  borderRadius: 10,
  height: '45px',
  padding: '14px 24px',
  minWidth: 200
}
export const buttonStyles = {
  background: 'var(--color-button-primary)',
  boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
  borderRadius: 10,
  padding: 12,
  height: 45,
  minWidth: 'unset'
}
const containerStyle = {
  paddingBottom: 150
}

interface Props {
  users: UserType[]
  currentPage: number
  totalElements: number
  match: any
  isLoadingUsers: boolean
}

const Professionals: FC<Props> = ({
  users,
  currentPage,
  totalElements,
  match,
  isLoadingUsers
}) => {
  const { params: { botId } } = match
  const [searchingValue, setSearchingValue] = useState('')
  const [isOpenUserModal, setIsOpenUserModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenSupervisionManagementModal, setIsOpenSupervisionManagementModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState<UserType>(null)

  useEffect(() => {
    Promise.all([
      getUsers(botId, currentPage, searchingValue),
      getManagers(botId)
    ]).then((responses) => {
      const hasSuccessfulResponse = responses.every(response => response)
      if (hasSuccessfulResponse) {
        setIsLoadingUsers(false)
      }
    })
  }, [currentPage])

  useEffect(() => {
    return () => {
      updateCurrentPage(0)
    }
  }, [])

  const searchingByValue = useRef(debounce((value) => {
    getUsers(botId, 0, value)
  }, DEFAULT_DEBOUNCE_TIME))

  const onChangeHandler = (e) => {
    const value = e.target.value
    setSearchingValue(value)
    searchingByValue.current(value)
  }

  const onChangePageHandler = (e, page) => {
    updateCurrentPage(page)
    getUsers(botId, page, searchingValue)
  }

  const deleteUserHandler = () => {
    setIsOpenDeleteModal(false)
    deleteUser(selectedUser.id).then(() => {
      getUsers(botId, currentPage, searchingValue)
    })
  }

  const openDeleteModalHandler = (user) => {
    setIsOpenDeleteModal(true)
    setSelectedUser(user)
  }

  const openSupervisionManagementModalHandler = (user) => {
    setIsOpenSupervisionManagementModal(true)
    setSelectedUser(user)
  }

  return (
    <UsersContainer style={containerStyle}>
      {!isLoadingUsers ? (
        <>
          <UsersHeadlineWrap>
            <Headline title='Professionals' />
          </UsersHeadlineWrap>
          <UsersControlLine>
            <UserSearchWrap>
              <Search
                value={searchingValue}
                onChange={onChangeHandler}
                placeholder='Search by name or email'
                inputStyle={inputSearchStyles}
              />
            </UserSearchWrap>
            <UsersButtonWrap>
              <Button
                style={buttonStyles}
                onClick={() => setIsOpenUserModal(true)}
              >
                <ButtonTitle>Invite new Professional</ButtonTitle>
              </Button>
            </UsersButtonWrap>
          </UsersControlLine>
          {!!users.length ? (
            <>
              <ProfessionalsTable
                users={users}
                botId={botId}
                openDeleteModalHandler={openDeleteModalHandler}
                openSupervisionManagementModalHandler={openSupervisionManagementModalHandler}
              />
              <Pagination
                page={currentPage}
                rowsPerPage={10}
                count={totalElements}
                onChangePage={onChangePageHandler}
              />
            </>
          ) : (
            <NoChartData
              isUserData={true}
              text='You have no Professionals yet. Start by pressing the button -  Invite new Professional'
            />
          )}
        </>
      ) : (
        <LoaderScreen />
      )}

      {isOpenUserModal &&
        <InviteUserModal
          botId={botId}
          isOpenUserModal={isOpenUserModal}
          onClose={() => setIsOpenUserModal(false)}
        />
      }
      {isOpenDeleteModal &&
        <DeleteUserModal
          open
          onClose={() => setIsOpenDeleteModal(false)}
          onDelete={deleteUserHandler}
          selectedUser={selectedUser}
        />
      }
      {isOpenSupervisionManagementModal &&
        <SupervisionManagementModal
          isOpenSupervisionManagementModal={isOpenSupervisionManagementModal}
          botId={botId}
          onClose={() => {
            getUsers(botId, currentPage, searchingValue)
            setIsOpenSupervisionManagementModal(false)
          }}
          selectedUser={selectedUser}
        />
      }
    </UsersContainer>
  )
}

const mapStateToProps = (state) => ({
  users: state.professionalsTab.users,
  currentPage: state.professionalsTab.currentPage,
  totalPages: state.professionalsTab.totalPages,
  totalElements: state.professionalsTab.totalElements,
  isLoadingUsers: state.professionalsTab.isLoadingUsers
})

export default withRouter(connect(mapStateToProps)(Professionals))
