export enum UserStatuses {
  EXPIRED = 'EXPIRED',
  SENT = 'SENT'
}

export enum TimeframeFormValues {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_7_DAYS = 'last_7_days',
  LAST_30_DAYS = 'last_30_days',
  ALL_TIME = 'all_time',
  CUSTOM = 'custom',
}

export enum UploadingStatuses {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export enum SupervisionStatuses {
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  UPCOMING = 'UPCOMING',
  TO_BE_COMPLETED = 'TO_BE_COMPLETED',
  OVERDUE = 'OVERDUE',
  SUBMITTED = 'SUBMITTED',
  COMPLETED = 'COMPLETED'
}

export enum ProfessionalRoles {
  PROFESSIONAL = 'CARER',
  MANAGER = 'SUPERVISOR'
}
