import React, { FC, useMemo } from 'react'
import { StatusContainer, StatusTitle } from './SupervisionStatus.styles'
import { SupervisionStatuses } from '../../helpers/constants'

interface Props {
  type: SupervisionStatuses
}

export const SupervisionStatus: FC<Props> = ({ type }) => {

  const status = useMemo(() => {
    const status = { title: '', color: '' }
    switch (type) {
      case SupervisionStatuses.UPCOMING:
        status.title = 'Upcoming'
        break
      case SupervisionStatuses.TO_BE_COMPLETED:
        status.title = 'To be completed'
        break
      case SupervisionStatuses.OVERDUE:
        status.title = 'Overdue'
        status.color = 'var(--color-text-error)'
        break
      case SupervisionStatuses.SUBMITTED:
        status.title = 'Submitted'
        status.color = 'var(--color-text-success)'
        break
      case SupervisionStatuses.NOT_ASSIGNED:
        status.title = 'Manager not set'
        status.color = 'var(--color-text-placeholder)'
        break
    }
    return status
  }, [type])

  return (
    <StatusContainer>
      <StatusTitle
        color={status.color}
      >
        { status.title }
      </StatusTitle>
    </StatusContainer>
  )
}
