import React, { FC, useEffect, useState } from 'react'
import {
  ActionButton,
  ActionContainer, CompletedSupervisionEmpty,
  CompletedSupervisionItem,
  CompletedSupervisionLabel,
  CompletedSupervisionList,
  CompletionStatusWrapper,
  FileAction,
  FileWrapper,
  ItemLabel,
  ItemStatusDate,
  Title,
} from './SupervisionManagementModal.styles'
import { ExternalLinkIcon } from 'uiKit/icons/ExternalLinkIcon'
import { DownloadIcon } from 'uiKit/icons/DownloadIcon'
import { getSessionsHistory } from '../../api/usersApi'
import { UserType } from '../../model/usersModel'
import LoaderScreen from '../../../../uiKit/loaders/loaderScreen'
import { downloadSupervisionResultHandler, downloadSupervisionTranscript } from '../../helpers/supervisionFileHelper'

interface Props {
  botId: string
  selectedUser: UserType
}

export const CompletedSupervisionTab: FC<Props> = ({ botId, selectedUser }) => {
  const [completedSupervisions, setCompletedSupervisions] = useState([])
  const [isLoadingCompletedSupervision, setIsLoadingCompletedSupervision] = useState(true)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    getSessionsHistory(botId, selectedUser.id, signal).then(completedSupervisions => {
      setCompletedSupervisions(completedSupervisions)
      setIsLoadingCompletedSupervision(false)
    })

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      {isLoadingCompletedSupervision ? (
        <LoaderScreen
          size={{
            circleSize: 60,
            border: 8
          }}
        />
      ) : (
        <>
          <CompletedSupervisionLabel>
            Completed supervisions are stored for X month after the date of completion.
          </CompletedSupervisionLabel>
          {!!completedSupervisions.length ? (
            <CompletedSupervisionList>
              {completedSupervisions.map((supervision, index) => (
                <CompletedSupervisionItem key={index}>
                  <CompletionStatusWrapper>
                    <ItemLabel>Date of completion</ItemLabel>
                    <ItemStatusDate>{new Date(supervision.completedAt).toLocaleDateString('en-GB')}</ItemStatusDate>
                  </CompletionStatusWrapper>

                  <FileWrapper>
                    <ItemLabel>Supervision results</ItemLabel>
                    <FileAction>
                      <Title
                        maxWidth={65}
                      >
                        { supervision.outputFormFileName }
                      </Title>
                      <ActionContainer>
                        <ActionButton
                          onClick={() => downloadSupervisionResultHandler(botId, supervision.id, supervision.outputFormFileName, false)}
                        >
                          <DownloadIcon />
                        </ActionButton>
                        <ActionButton
                          onClick={() => downloadSupervisionResultHandler(botId, supervision.id, supervision.outputFormFileName, true)}
                        >
                          <ExternalLinkIcon />
                        </ActionButton>
                      </ActionContainer>
                    </FileAction>
                  </FileWrapper>

                  <FileWrapper>
                    <ItemLabel>Supervision transcript</ItemLabel>
                    <FileAction>
                      <Title
                        maxWidth={65}
                      >
                        { supervision.transcriptFileName }
                      </Title>
                      <ActionContainer>
                        <ActionButton
                          onClick={() => downloadSupervisionTranscript(botId, supervision.id, supervision.transcriptFileName, false)}
                        >
                          <DownloadIcon />
                        </ActionButton>
                        <ActionButton
                          onClick={() => downloadSupervisionTranscript(botId, supervision.id, supervision.transcriptFileName, true)}
                        >
                          <ExternalLinkIcon />
                        </ActionButton>
                      </ActionContainer>
                    </FileAction>
                  </FileWrapper>
                </CompletedSupervisionItem>
              ))}
            </CompletedSupervisionList>
          ) : (
            <CompletedSupervisionEmpty>
              No completed supervisions available.
            </CompletedSupervisionEmpty>
          )}
        </>
      )}
    </>
  )
}
