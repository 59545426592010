import React, { FC, useState } from 'react'
import { useStore } from 'react-redux'
import Popover from '@material-ui/core/Popover'
import { VerticalThreeDots } from 'uiKit/icons/VerticalThreeDots'
import CheckIcon from 'uiKit/icons/CheckIcon'
import { ChevronRight } from 'uiKit/icons/Icons'
import { ProfessionalRoles } from '../../helpers/constants'
import { UserType } from '../../model/usersModel'
import { getUsers, resendUserInvitation, resetUserPassword, updateUser } from '../../api/usersApi'
import {
  ActionSelectorButton,
  ActionSelectorContainer, IconContainer,
  PopoverButton, SubMenu,
} from './ActionSelector.styles'
import classes from './styles.module.scss'

interface Props {
  user: UserType
  botId: string
  openDeleteModalHandler: (user: UserType) => void
  openSupervisionManagementModalHandler: (user: UserType) => void
}

export const ActionSelector: FC<Props> = ({
  user,
  botId,
  openDeleteModalHandler,
  openSupervisionManagementModalHandler
}) => {
  const store = useStore()
  const { professionalsTab: { currentPage } } = store.getState()
  const [isOpenActionSelector, setOpenActionSelector] = useState(false)
  const [target, setTarget] = useState(null)
  const [hasSubMenu, setHasSubMenu] = useState(false)

  const resendInviteAction = () => {
    setOpenActionSelector(false)
    resendUserInvitation(user.id).then(() => {
      getUsers(botId, currentPage)
    })
  }

  const resetPasswordAction = () => {
    setOpenActionSelector(false)
    resetUserPassword(user.id)
  }

  const deleteUserAction = () => {
    setOpenActionSelector(false)
    openDeleteModalHandler(user)
  }

  const manageSupervisionAction = () => {
    setOpenActionSelector(false)
    openSupervisionManagementModalHandler(user)
  }

  const updateRoleHandler = (role: ProfessionalRoles) => {
    const userData = {
      ...user,
      role
    }
    updateUser(userData).then(() => {
      setHasSubMenu(false)
      setOpenActionSelector(false)
      getUsers(botId, currentPage)
    })
  }

  return (
    <ActionSelectorContainer>
      <ActionSelectorButton
        onClick={() => setOpenActionSelector(true)}
        ref={node => setTarget(node)}
      >
        <VerticalThreeDots />
      </ActionSelectorButton>
      <Popover
        classes={{ paper: classes.popover }}
        open={isOpenActionSelector}
        anchorEl={target}
        transformOrigin={{
          horizontal: 120,
          vertical: -35
        }}
        onClose={() => {
          setOpenActionSelector(prevState => !prevState)
          setHasSubMenu(false)
        }}
      >
        <PopoverButton
          onMouseEnter={() => setHasSubMenu(true)}
          hasSubMenu={hasSubMenu}
        >
          Role
          <ChevronRight />
          {hasSubMenu &&
            (<SubMenu>
              <PopoverButton
                onClick={() => updateRoleHandler(ProfessionalRoles.PROFESSIONAL)}
              >
                Professional
                {user.role === ProfessionalRoles.PROFESSIONAL &&
                  (<IconContainer>
                    <CheckIcon />
                  </IconContainer>)
                }
              </PopoverButton>
              <PopoverButton
                onClick={() => updateRoleHandler(ProfessionalRoles.MANAGER)}
              >
                Manager
                {user.role === ProfessionalRoles.MANAGER &&
                  (<IconContainer>
                    <CheckIcon />
                  </IconContainer>)
                }
              </PopoverButton>
            </SubMenu>)
          }
        </PopoverButton>
        <PopoverButton
          onClick={resetPasswordAction}
          onMouseEnter={() => setHasSubMenu(false)}
        >
          Reset Password
        </PopoverButton>
        <PopoverButton
          onClick={manageSupervisionAction}
          onMouseEnter={() => setHasSubMenu(false)}
          disabled={!user.manager}
        >
          Manage Supervision
        </PopoverButton>
        <PopoverButton
          onMouseEnter={() => setHasSubMenu(false)}
          disabled={!user.manager}
        >
          Manage Knowledge Check
        </PopoverButton>
        <PopoverButton
          hasAttentionStyles={true}
          onClick={deleteUserAction}
          onMouseEnter={() => setHasSubMenu(false)}
        >
          Delete
        </PopoverButton>
      </Popover>
    </ActionSelectorContainer>
  )
}
