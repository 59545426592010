import React from 'react'

import './Loader.css'

const Loader = ({ size }) => (
  <div
    className="Loader"
  >
    <div
      style={{
        width: size?.circleSize,
        height: size?.circleSize,
        borderWidth: size?.border
      }}
      className="Loader__spinner"
    />
  </div>
)

export default Loader
