export const styles = () => ({
  containerReceived: {
    width: '100%',
    margin: '4px 0px',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  containerSend: {
    width: '100%',
    margin: '4px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  timeZone: {
    fontSize: 10,
    color: 'var(--color-spun-pearl-grey)',
    fontWeight: 300,
    whiteSpace: 'nowrap'
  },
  statusWrap: {
    padding: '0px 8px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px'
  },
  iconWithTooltip: {
    position: 'relative',
    display: 'inline-block',
    cursor: 'pointer',
  },
  tooltip: {
    visibility: 'hidden',
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center',
    padding: '5px',
    borderRadius: '8px',
    position: 'absolute',
    bottom: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    zIndex: 1000,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  iconWithTooltipHover: {
    '&:hover $tooltip': {
      visibility: 'visible',
      opacity: 0.8,
    },
  },
})
