import React from 'react'
import LoaderSmall from '../loader'
import classes from './styles.module.scss'

const LoaderScreen = ({ size }: { size?: any }) => {
  return (
    <div className={classes.container}>
      <LoaderSmall
        showLoader={true}
        size={size}
      />
    </div>
  )
}

export default LoaderScreen
