import styled from 'styled-components'
import {
  InputEditorContainer as InputEditorContainerBase,
  InputEditorHolder as InputEditorHolderBase,
  InputEditorInput as InputEditorInputBase,
  InputEditorButton as InputEditorButtonBase
} from '../../../professionals/components/InputEditor/InputEditor.styles'

export const InputEditorContainer = styled(InputEditorContainerBase)``

export const InputEditorHolder = styled(InputEditorHolderBase)`
  margin-top: 3px;
`

export const InputEditorInput = styled(InputEditorInputBase)``

export const InputEditorButton = styled(InputEditorButtonBase)`
  margin-top: 15px;
`
