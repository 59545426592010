import api from 'services/api'
import { logoutOnRequestOrResponseJson, customResponseParser, alertError } from 'api'
import { BASE_URL } from 'configs'
import { SourceTypes } from '../constants'
import { addQueryStringParameter } from '../../../helpers'

export const getKnowledgeSources = (botId: number, type: string) => {
  return api.get(`${BASE_URL}/bot/${botId}/knowledge?type=${type}`)
}

export const getTrainingStatus = (botId: number) => {
  return api.get(`${BASE_URL}/bot/${botId}/knowledge/status`)
}

export const updateWebsiteKnowledgeSources = (
  botId: number,
  body: { url: string; type: SourceTypes, hierarchyLevel: string },
): Promise<{ id: string; type: SourceTypes; url: string } | undefined | null> => {
  return api.post(`${BASE_URL}/bot/${botId}/knowledge/upload`, body)
}

export const updateTextKnowledgeSources = (
  botId: number,
  body: { title: string; text: string; replyId?: number; type: SourceTypes, hierarchyLevel?: string },
  needTrain: boolean,
) => {
  return api.post(`${BASE_URL}/bot/${botId}/knowledge/upload?train=${needTrain}`, body)
}

export const updateFileKnowledgeSources = (botId: number, body, signal, level) => {
  return fetch(`${BASE_URL}/bot/${botId}/knowledge/upload?level=` + level, {
    credentials: 'include',
    method: 'POST',
    body: body,
    signal: signal,
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => customResponseParser(response))
}

export const getTextInsertSource = (
  botId: number,
  improvementSourceId: string,
): Promise<{ type: SourceTypes; status: string; _id: string; title?: string; text?: string }> => {
  return api.get(`${BASE_URL}/bot/${botId}/knowledge/${improvementSourceId}`)
}

export const updateTextInsert = (
  botId: number,
  payloadBody: { title: string; text?: string; type: SourceTypes; _id: string },
): Promise<{ replyId: number; status: string; title: string; text?: string; type: SourceTypes }> => {
  return api.put(`${BASE_URL}/bot/${botId}/knowledge/update`, payloadBody)
}

export const saveNLPConfig = (
  botId: number,
  payload: { prompt: string; model: string },
): Promise<{ data: string } | undefined> => {
  return api.put(`${BASE_URL}/bot/${botId}/knowledge/config`, payload)
}

export const getDefaultCustomPrompt = (): Promise<string> => {
  return api.get(`${BASE_URL}/knowledge/prompt/default`)
}

export const getNLPConfig = (botId: number): Promise<{ prompt: string; model: string } | undefined> => {
  return api.get(`${BASE_URL}/bot/${botId}/knowledge/config`)
}

export const getAvailableGPTModels = (): Promise<string[] | undefined> => {
  return api.get(`${BASE_URL}/knowledge/models`)
}

export const deleteKnowledgeSources = (botId: number, id: number) => {
  return api.delete(`${BASE_URL}/bot/${botId}/knowledge?source_id=${id}`)
}

export const deleteSourceSubPage = (botId: number, sourceId: string, pageId: string) => {
  return api.delete(`${BASE_URL}/bot/${botId}/knowledge/${sourceId}/page/${pageId}`)
}

export const trainKnowledgeSources = (botId: number, body: string[]) => {
  return api.post(`${BASE_URL}/bot/${botId}/knowledge/train`, body)
}

export const getKnowledgeSourcePages = (
  sourceId: string,
  botId: number,
): Promise<{ url: string; _id?: string }[] | undefined> => {
  return api.get(`${BASE_URL}/bot/${botId}/knowledge/${sourceId}/pages`)
}

type getSuccessfulKnowledgeBaseReplyCountParams = {
  botId: number
  languageId?: string | number
  sourceTitle?: string | number
  startDate: string
  endDate: string
}
type getKnowledgeBaseRepliesParams = {
  botId: number
  page: number
  size: number
  startDate: string
  endDate: string
  languageId?: string | number
  sourceTitle?: string
  search?: string
}
export type KnowledgeBaseReply = {
  id: number
  improvementSourceId?: string
  userId: number
  chatId: string
  userPhrase: string
  odinReply: string
  sources: Source[],
  sourceId?: string,
  source?: string,
  sourceType?: SourceTypeMap,
  sourceName?: string,
  sentAt: string
  isFailed: boolean
  isStoppedByUser?: boolean
}

export enum SourceTypeMap {
  WEBSITE = 'WEBSITE',
  TEXT = 'TEXT',
  FILE = 'FILE',
}

export interface Source {
  sourceId?: string,
  source?: string;
  sourceType?: SourceTypeMap;
  sourceName?: string;
}

export const getKnowledgeBaseReplies = ({
  botId,
  page,
  size,
  startDate,
  endDate,
  languageId,
  sourceTitle,
  search,
}: getKnowledgeBaseRepliesParams): Promise<{ data: KnowledgeBaseReply[]; totalElements: number }> => {
  let url = `${BASE_URL}/bot/${botId}/knowledge/replies?page=${page}&size=${size}&from=${startDate}&to=${endDate}`

  if (languageId && languageId !== 'all') url = addQueryStringParameter(url, 'languageId', String(languageId))

  if (sourceTitle && sourceTitle !== 'all') {
    url = addQueryStringParameter(url, 'sourceTitle', sourceTitle)
  }

  if (search) url = addQueryStringParameter(url, 'searchQuery', search)

  return api
    .get(url)
    .then(res => {
      const content = Array.isArray(res.content)
        ? res.content.map(item => ({
          ...item,
          userPhrase: item.userPhrase ? decodeUnicode(item.userPhrase) : item.userPhrase
        }))
        : []

      return {
        data: content,
        totalElements: res.totalElements || 0
      }
    })
    .catch(error => {
      console.error(error)
      alertError('Sorry, something went wrong. Please, ping support!')
      return { data: [], totalElements: 0 }
    })
}

export const getSuccessfulKnowledgeBaseReplyCount = ({
  botId,
  languageId,
  sourceTitle,
  startDate,
  endDate,
}: getSuccessfulKnowledgeBaseReplyCountParams): Promise<{ count: number }> => {
  let url = `${BASE_URL}/bot/${botId}/knowledge/replies/countSuccessful?from=${startDate}&to=${endDate}`

  if (languageId && languageId !== 'all') {
    url = addQueryStringParameter(url, 'languageId', String(languageId))
  }
  if (sourceTitle && sourceTitle !== 'all') {
    url = addQueryStringParameter(url, 'sourceTitle', sourceTitle)
  }

  return api.get(url).catch(() => {
    alertError('Sorry, something went wrong. Please, ping support!')
    return { count: 0 }
  })
}

const decodeUnicode = (str: string): string =>
  str.replace(/\\u([\dA-Fa-f]{4})/g, (_, code: string) =>
    String.fromCharCode(parseInt(code, 16)))
