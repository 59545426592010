import React, { FC, useState } from 'react'
import { useStore } from 'react-redux'
import Select from 'react-select'
import SelectorStyles from 'tabs/nlp/constants/selectorStyles'
import { assignManager, getManagers, getUsers } from '../../api/usersApi'
import { ManagerType, UserType } from '../../model/usersModel'

interface Props {
  manager: ManagerType
  user: UserType
  botId: string
}

export const RoleSelector: FC<Props> = ({
  manager,
  user,
  botId
}) => {
  const store = useStore()
  const { professionalsTab: { currentPage } } = store.getState()
  const [selectedValue, setSelectedValue] = useState(manager)
  const [managers, setManagers] = useState<ManagerType[]>([])

  const onOpenHandler = async () => {
    const fetchedManagers = await getManagers(botId)
    const filteredManagers = fetchedManagers.filter(
      (manager: { value: number }) => manager.value !== user.id
    )
    setManagers(filteredManagers)
  }

  const onChangeHandler = (selectedValue) => {
    setSelectedValue(selectedValue)
    assignManager(botId, user.id, selectedValue.value).then(() => {
      getUsers(botId, currentPage)
    })
  }

  return (
    <Select
      isSearchable={true}
      options={managers}
      value={selectedValue?.label ? selectedValue : null}
      onChange={onChangeHandler}
      onMenuOpen={onOpenHandler}
      placeholder='Assign a manager'
      styles={{
        ...SelectorStyles,
        indicatorSeparator: provided => ({
          ...provided,
          display: 'none',
        })
      }}
    />
  )
}
