import React, { FC } from 'react'
import Table from 'uiKit/table/Table'
import TableRow from 'uiKit/table/TableRow'
import TableCell from 'uiKit/table/TableCell'
import { UserType } from '../../model/usersModel'
import { InputEditor } from '../InputEditor/InputEditor'
import { UserEmail, UserEmailContainer, UserStatus } from './ProfessionalsTable.styles'
import { UserStatuses } from '../../helpers/constants'
import { RoleSelector } from '../RoleSelector/RoleSelector'
import { ActionSelector } from '../ActionSelector/ActionSelector'
import { ExportConversations } from '../ExportConversations/ExportConversations'
import { SupervisionStatus } from '../SupervisionStatus/SupervisionStatus'

const columnTitles = [
  { title: 'First Name', maxWidth: 160 },
  { title: 'Last Name', maxWidth: 160 },
  { title: 'Email', maxWidth: 200 },
  {
    title: 'Manager',
    maxWidth: 180,
    tooltipText: 'Assigned Managers will receive notifications about Supervisions and Knowledge Checks related to selected Care Processional.'
  },
  { title: 'Supervision status', maxWidth: 120 },
  { title: 'Export conversations logs', maxWidth: 205 },
  { title: 'Action', maxWidth: 50 }
]

const getRowBackground = (index: number) => {
  return { background: index % 2 === 0 ? '' : 'var(--color-table-row-primary)' }
}

interface Props {
  users: UserType[]
  botId: string
  openDeleteModalHandler: (user: UserType) => void
  openSupervisionManagementModalHandler: (user: UserType) => void
}

export const ProfessionalsTable: FC<Props> = ({
  users,
  botId,
  openDeleteModalHandler,
  openSupervisionManagementModalHandler
}) => {
  const userStatusHandler = (status) => {
    let statusStr = ''
    switch (status) {
      case UserStatuses.EXPIRED:
        statusStr = 'Invitation link has expired.'
        break
      case UserStatuses.SENT:
        statusStr = 'Pending invitation'
        break
    }

    return statusStr
  }

  return (
    <Table
      titles={columnTitles}
    >
      {users.map((user, index) => (
        <TableRow
          key={user.id}
          style={{
            alignItems: 'center',
            ...getRowBackground(index)
          }}
        >
          <TableCell style={{ maxWidth: columnTitles[0].maxWidth }}>
            <InputEditor
              user={user}
              value={user.first_name}
              userKey='first_name'
              botId={botId}
            />
          </TableCell>
          <TableCell style={{ maxWidth: columnTitles[1].maxWidth }}>
            <InputEditor
              user={user}
              value={user.last_name}
              userKey='last_name'
              botId={botId}
            />
          </TableCell>
          <TableCell style={{ maxWidth: columnTitles[2].maxWidth }}>
            <UserEmailContainer>
              <UserEmail>
                {user.email}
              </UserEmail>
              <UserStatus status={user.link_status}>
                {userStatusHandler(user.link_status)}
              </UserStatus>
            </UserEmailContainer>
          </TableCell>
          <TableCell style={{ maxWidth: columnTitles[3].maxWidth }}>
            <RoleSelector
              manager={user.manager}
              user={user}
              botId={botId}
            />
          </TableCell>
          <TableCell style={{ maxWidth: columnTitles[4].maxWidth }}>
            <SupervisionStatus type={user.supervision_status} />
          </TableCell>
          <TableCell style={{ maxWidth: columnTitles[5].maxWidth }}>
            <ExportConversations
              botId={botId}
              userId={user.id}
            />
          </TableCell>
          <TableCell style={{ maxWidth: columnTitles[6].maxWidth }}>
            <ActionSelector
              user={user}
              botId={botId}
              openDeleteModalHandler={openDeleteModalHandler}
              openSupervisionManagementModalHandler={openSupervisionManagementModalHandler}
            />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
