import React, { memo, useMemo } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Message from '../Message'

import { styles } from './styles'

const MessageGroup = props => {
  const { classes, messages, date, messageIntoView, messageId, reverse, hash, searchQuery } = props

  const setRef = id => el => {
    const message = messages.find(msg => msg.chatHistoryId === id)
    if (
      message?.messagePreviewTranslated?.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      messageIntoView.current = el
    }
  }

  const renderMessages = messages
    ?.slice()
    ?.reverse()
    ?.filter(msg => msg?.lastEventAt?.split('T')[0] === date)

  const lastReactionElementIndex = useMemo(() => {
    let lastIndex
    renderMessages.some((val, index, array) => {
      const reverseIndex = array.length - 1 - index
      const message = JSON.parse(_.get(array, `${reverseIndex}.messagePreviewTranslated`))?.text
      return message?.enableReaction && (lastIndex = reverseIndex)
    })
    return lastIndex
  }, [renderMessages])

  return (
    <div className={classes.container}>
      {renderMessages.map((message, index) => (
        <div
          ref={el => setRef(message.chatHistoryId)(el)}
          key={message.chatHistoryId}
        >
          <Message
            messages={messages}
            msg={message}
            highlight={
              searchQuery &&
              message.messagePreviewTranslated?.toLowerCase().includes(searchQuery.toLowerCase())
            }
            showConversationId={message?.conversationId !== renderMessages[index - 1]?.conversationId}
            searchQuery={searchQuery}
          />
        </div>
      ))}
    </div>
  )
}

MessageGroup.propTypes = {
  classes: PropTypes.object,
  messages: PropTypes.array,
  date: PropTypes.string,
  searchQuery: PropTypes.string,
}

export default memo(withStyles(styles)(MessageGroup))
