import styled from 'styled-components'
import { MINE_SHAFT, WILD_SAND } from 'constants/colors'

export const TimeSlotSection = styled.div`
  display: flex;
  margin-bottom: 24px;
`

export const FilesSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0 24px;
  margin-bottom: 24px;
`

export const ItemLabel = styled.div`
  color: var(--color-text-primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 8px;
  
  & .modal-tooltip {
    width: 250px;
    padding: 8px;
    border: 1px solid var(--color-issabeline-grey);
    box-sizing: border-box;
    box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
    border-radius: 10px;
    z-index: 9999;
    opacity: 1;
    &.type-dark {
      background-color: ${MINE_SHAFT}
    }
  }
`

export const ItemStatusDate = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 24px;
`

export const DatepickerActionBar = styled.div`
  display: flex;
  justify-content: right;
`

export const DatepickerActionButton = styled.button`
  margin-right: 12px;
  background-color: ${props => (props.isPrimary ? 'var(--color-button-primary)' : 'transparent')};
  padding: 4px 8px;
  border: none;
  border-radius: 2px;
  color: ${props => (props.isPrimary ? 'var(--color-text-on-primary)' : 'var(--color-button-primary)')};
  
  &:last-child {
    margin: 0;
  }
`

export const CompletionStatusWrapper = styled.div`
  color: var(--Text, #3A3F62);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`

export const FileWrapper = styled.div`
  width: 100%;
`

export const FileAction = styled.div`
  display: flex;
  border-radius: 10px;
  border: 1px solid var(--color-input-stroke);
  background: ${props => (props.isDisabled ? WILD_SAND : 'var(--color-light)')};
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.div`
  max-width: ${props => props.maxWidth || 160}px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${props => (props.isDisabled ? 'var(--color-text-placeholder)' : 'var(--color-text-primary)')}
`

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ActionButton = styled.button`
  border: none;
  background: var(--color-light);
  padding: 0;
  margin-right: 16px;
  
  & svg {
    display: block;
    width: 20px;
    height: 20px;
    & path {
      stroke: var(--color-text-secondary);
    }
  }
  
  &:disabled {
    & svg {
      & path {
        stroke: var(--color-text-placeholder);
      }
    }
  }
  
  &:last-child {
    margin-right: 0;
  }
`

export const SummarySection = styled.div`
  
`

export const SummaryTextarea = styled.textarea`
  position: static;
  padding: 12px 16px;
  background: var(--color-light);
  border: 1px solid var(--color-mischka-grey);
  height: 105px;
  margin-bottom: 8px;
  
  &:disabled {
    background: ${WILD_SAND};
    color: var(--color-text-placeholder);
  }
`

export const AttentionWarning = styled.div`
  color: var(--color-text-error);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`

export const CompletedSupervisionLabel = styled.div`
  margin-bottom: 40px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-text-secondary);
`

export const CompletedSupervisionList = styled.ul`
  margin: 0;
  padding: 0;
`

export const CompletedSupervisionEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 64px);
`

export const CompletedSupervisionItem = styled.li`
  list-style-type: none;
  margin-bottom: 24px;
  display: flex;
  padding: 20px 24px;
  gap: 24px;

  border-radius: 10px;
  border: 1px solid var(--static-input-stroke, #EBEBEF);
  background: #FDFDFD;
  
  &:last-child {
    margin-bottom: 0;
  }
`
